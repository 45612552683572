<template src="./medicalRecord.html"></template>

<script>
import history from "../patientHistory/patientHistory";
import medicalCare from "../medicalCare/medicalCare";
import patientData from "../patientData/patientData";
import videoCall from "../videoCallMedicalAssistance/videoCallMedicalAssistance";
import mixins from "../../../../mixins/mixins.js";
export default {
  mixins: [mixins],
  name: "patient-medical-record",
  props: {
    medRecId: Number,
    justSeeNew: Boolean,
    justSeePerformed: Boolean,
    medicalCareType: String,
    patientId: Number,
  },
  components: {
    history,
    medicalCare,
    patientData,
    videoCall,
  },
  data() {
    return {
      tabIndex: 0,
      webVersion: true,
      sizeColLg: 12,
      typeOfMedicalCare: 1,
      showMedicalCareTab: false,
      showVideoCall: true,
      showHistoric: false,
    };
  },
  watch: {
    tabIndex: function (newVal) {
      const vm = this;
      vm.tabIndex = newVal;
    },
    showMedicalCareTab: function (newVal) {
      const vm = this;
      vm.showMedicalCareTab = newVal;
    },
  },
  mounted() {
    const vm = this;
    vm.verifyParamsUrl();
  },
  created() {
    const vm = this;
    window.addEventListener("resize", vm.handleResize);
    vm.handleResize();
  },
  destroyed() {
    const vm = this;
    window.removeEventListener("resize", vm.handleResize);
  },
  methods: {
    verifyParamsUrl() {
      const vm = this;
      vm.showMedicalCareTab = vm.$props.justSeeNew !== true ? true : false;
      vm.typeOfMedicalCare = vm.$props.medicalCareType;
      if (vm.typeOfMedicalCare == "video") {
        if (vm.$props.justSeeNew === true) {
          vm.showVideoCall = false;
          vm.sizeColLg = 12;
        } else if (vm.$props.justSeePerformed === true) {
          vm.showVideoCall = false;
          vm.sizeColLg = 12;
        } else {
          vm.showVideoCall = true;
          vm.sizeColLg = 8;
        }
      } else {
        vm.showVideoCall = false;
        vm.sizeColLg = 12;
      }
    },
    handleResize() {
      const vm = this;
      if (window.innerWidth < 740) {
        vm.webVersion = false;
      } else {
        vm.webVersion = true;
      }
    },
    goToPatientData() {
      const vm = this;
      vm.tabIndex = 0;
    },
    goToMedicalCare() {
      const vm = this;
      vm.tabIndex = 1;
    },
    goToPatientHistoric() {
      const vm = this;
      if (vm.$props.justSeeNew === true) {
        vm.tabIndex = 1;
      } else {
        vm.tabIndex = 2;
      }
    },
  },
};
</script>

<style lang="scss" src="./medicalRecord.scss">
</style>