<template src="./patientHistory.html"></template>
<script>
import mixins from "../../../../mixins/mixins.js";
export default {
  name: "history",
  mixins: [mixins],
  props: ["patientId"],
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
      showWebTable: true,
      histories: [],
      showGeneralHistory: true,
      idMedicalRecord: 0,
      isNewAppointment: false,
      currentx: 1,
      numPages: 0,
      pagination: false,
      showButtonGoToMedicalCare: false,
      showButtonGoToPatientData: false,
    };
  },
  created() {
    const vm = this;
    window.addEventListener("resize", vm.handleResize);
    vm.handleResize();
  },
  destroyed() {
    const vm = this;
    window.removeEventListener("resize", vm.handleResize);
  },
  mounted: function () {
    const vm = this;
    if (
      vm.$route.params.justSeeNew.toString() == "false" &&
      vm.$route.params.justSeePerformed.toString() == "true" &&
      vm.$route.params.typeOfMedicalCare.toString()=== "performed"
    ) {
      vm.showButtonGoToMedicalCare = true;
      vm.showButtonGoToPatientData = false;
    }

    vm.init();
  },
  watch: {
    currentx: function () {
      const vm = this;
      vm.handleChangePage();
    },
  },
  methods: {
    init() {
      const vm = this;
      let page = 0;
      if (vm.currentx == 1) {
        page = 0;
      } else {
        if (vm.currentx > 1) {
          page = vm.currentx - 1;
        }
      }
      vm.axios
        .get(
          vm.$store.state.filooServer +
            "doctor/my-patients/" +
            vm.$props.patientId +
            "?page=" +
            page +
            "&size=10&sort=id,desc",
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function (response) {
          if (response.status == 200) {
            vm.histories = response.data.content;
            vm.numPages = response.data.totalPages;
            vm.pagination = vm.numPages > 1;
          }
        })
        .catch(function (error) {
          console.log("Deu erro: " + error);
        });
    },
    handleChangePage() {
      const vm = this;
      vm.init();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    goToMedicalCare() {
      const vm = this;
      vm.$emit("historicGoBackToMedicalCare");
    },
    goToPatientData() {
      const vm = this;
      vm.$emit("historicGoBackToPatientData");
    },
    handleResize() {
      const vm = this;
      vm.window.width = window.innerWidth;
      vm.window.height = window.innerHeight;
      if (vm.window.width < 740) {
        vm.showWebTable = false;
      } else {
        vm.showWebTable = true;
      }
    },
    exit() {
      const vm = this;
      vm.$swal({
        title: "Atenção",
        text: "Os dados da consulta que não foram gravados serão perdidos. Confirma a saída do atendimento?",
        icon: "warning",

        buttons: ["Não", "Sim"],
      })
        .then(function (value) {
          if (value === true) {
            vm.$router.push("/appointment");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
<style lang="scss" src="./patientHistory.scss">
</style>