<template src="./medicalCare.html"></template>
<script>
import mixins from "../../../../mixins/mixins.js";
export default {
  name: "medical-care",
  props: {
    medRecId: Number,
    justSeeNew: Boolean,
    justSeePerformed: Boolean,
    medicalCareType: String,
    patientId: Number,
  },
  mixins: [mixins],
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
      showEndButtons: true,
      token: "",
      printButtonClicked: "",
      someMedicines: "",
      colorx: "#ffae42",
      prescriptionDrugsShow: false,
      examRequestShow: false,
      exams: [],
      options: [
        { text: "Pago", value: true },
        { text: "Gratuito", value: false },
      ],
      examsAdd: [{ id: "", description: "" }],
      commonDrugAdd: [],
      commonDrugs: [
        {
          active: true,
          product: "_",
          activePrinciple: "_",
          presentation: "_",
          laboratory: "_",
          usage: null,
          id: 0,
        },
      ],
      charsCommonDrugs: [],
      psychotropicControlledDrugsAdd: [],
      controlledDrugs: [
        {
          active: true,
          product: "_",
          activePrinciple: "_",
          presentation: "_",
          laboratory: "_",
          usage: null,
          id: 0,
        },
      ],
      charsControlledDrugs: [],
      prescriptionDrugs: [
        {
          active: true,
          product: "_",
          activePrinciple: "_",
          presentation: "_",
          laboratory: "_",
          usage: null,
          id: 0,
        },
      ],
      prescriptionDrugsAdd: [],
      charsPrescriptionDrugs: [],
      diagnosticHypothesis: [],
      diagnosticHypothesisAdd: [{ id: "", description: "" }],
      charsDiagnosticHypothesis: [],
      returnPatient: false,
      dischargedPatient: false,
      paid: null,
      patientHealthData: [],
      registrationData: [],
      status: [],
      conduct: [],
      attendance: [],
      someExams: "",
      medicalRecommendations: "",
      appointment: [],
      patientReturn: false,
      patientDischarge: false,
      clickedSaveChanges: false,
      inputDayNextSteps: null,
      mainComplaint: "",
      consultationDuration: "",
      clinicalExam: "",
      popupSendFiles: false,
      documentData: "",
      files: [],
      formData: "",
      typeEmailRadio: "",
      pageCommon: 0,
      totalPagesCommon: 0,
      searchCommon: "",
      commonDrugsIsLoading: false,
      pagePrescription: 0,
      totalPagesPrescription: 0,
      searchPrescription: "",
      prescriptionDrugsIsLoading: false,
      pageControlled: 0,
      totalPagesControlled: 0,
      searchControlled: "",
      controlledDrugsIsLoading: false,
      tabIndexDrugs: 0,
      canEdit: false,
    };
  },
  watch: {
    patientDischarge(newVal) {
      const vm = this;
      if (newVal === true) {
        vm.inputDayNextSteps = null;
      }
    },
  },
  created() {
    const vm = this;
    window.addEventListener("resize", vm.handleResize);
    vm.handleResize();
  },
  mounted() {
    const vm = this;
    vm.getExams();
    vm.getMedicinesCommon();
    vm.getMedicinesPrescription();
    vm.getMedicinesControlled();
    vm.getDiagnosticHypothesis();
    vm.addCommonDrug();
    vm.addPrescriptionDrugs();
    vm.addControlledDrugs();
    vm.getPatientHealthData();
    vm.getRegistrationData();
    vm.getMedicalRecordStatus();
    vm.getConduct();
    vm.getAttendance();
    vm.calculateIMC();
    vm.getToken();
  },
  destroyed() {
    const vm = this;
    window.removeEventListener("resize", vm.handleResize);
  },
  methods: {
    handleResize() {
      const vm = this;
      vm.window.width = window.innerWidth;
      vm.window.height = window.innerHeight;
      if (vm.window.width < 690) {
        vm.showEndButtons = false;
      } else {
        vm.showEndButtons = true;
      }
    },
    printMedicalRecord() {
      const vm = this;
      vm.$bvModal.show("medical-care-popup-print-medical-record");
    },
    validationSave() {
      const vm = this;
      let fieldInvalid = false;
      //Validando Anammese
      if (vm.patientDischarge === false && vm.patientReturn === false) {
        vm.showNotifyWarning("Selecione o próximo passo do paciente.", 4000);
        fieldInvalid = true;
      }
      if (vm.patientReturn === true && vm.inputDayNextSteps === null) {
        vm.showNotifyWarning(
          "Digite a quantidade de dias para o retorno do paciente.",
          4000
        );
        fieldInvalid = true;
      }
      if (vm.isEmpty(vm.mainComplaint) === true) {
        vm.showNotifyWarning(
          "O campo <b><i> ' Queixa principal ' </i></b> é obrigatório. Preencha e tente novamente.",
          4300
        );
        fieldInvalid = true;
      }
      //Validando Hipótese diagnóstica - CID
      let emptyCID = false;
      for (const d in vm.diagnosticHypothesisAdd) {
        if (vm.diagnosticHypothesisAdd[d].id == "") {
          emptyCID = true;
        }
      }
      if (emptyCID === true) {
        vm.showNotifyWarning(
          "O campo <b><i>' Hipótese diagnóstica - CID ' </i></b> é obrigatório. Preencha e tente novamente.",
          4300
        );
        fieldInvalid = true;
      }
      //Validando próximo passos
      if (vm.patientReturn === true && vm.inputDayNextSteps == 0) {
        vm.showNotifyWarning(
          "A quantidade de dias para o retorno não pode ser <b><i>' 0 ' </i></b>. Aumente e tente novamente.",
          4300
        );
        fieldInvalid = true;
      }
      if (vm.patientDischarge === false && vm.paid == null) {
        vm.showNotifyWarning(
          "Selecione uma das opções referente ao atendimento <b><i>(Pago ou Gratuito)<i/></b>.",
          4000
        );
        fieldInvalid = true;
      }
      if (vm.patientDischarge === false && vm.inputDayNextSteps > 0) {
        vm.selectNextStep(vm.inputDayNextSteps);
      } else {
        if (vm.patientDischarge === true) {
          vm.selectNextStep(0);
        }
      }
      if (fieldInvalid === false) {
        vm.savePatientAttendance();
      }
    },
    saveChanges() {
      const vm = this;
      vm.canEdit = true;
      vm.clickedSaveChanges = true;
      vm.validationSave();
    },
    endService() {
      const vm = this;
      vm.clickedSaveChanges = false;
      vm.canEdit = vm.attendance.canEdit;
      vm.validationSave();
    },
    savePatientAttendance() {
      const vm = this;
      const diagnosis = {
        arterialPressure: null,
        canEdit: vm.canEdit === true ? vm.canEdit : vm.attendance.canEdit,
        clinicalExam: vm.clinicalExam,
        diagnostics: vm.diagnosticHypothesisAdd,
        duration: vm.consultationDuration,
        heartRate: null,
        height: vm.attendance.height,
        imc: vm.attendance.imc,
        interrogationAboutVariousDevices: null,
        mainComplaint: vm.mainComplaint,
        patientId: vm.attendance.patientId,
        patientName: vm.attendance.patientName,
        phisicalExamDescribed: null,
        progressiveHistoryPresentMolestation: null,
        respiratoryFrequency: null,
        someExams: vm.someExams,
        someMedicines: vm.someMedicines,
        weight: vm.attendance.weight,
      };

      vm.axios
        .put(
          vm.$store.state.filooServer +
            "medical-records/" +
            vm.$props.medRecId +
            "/attendance",
          diagnosis,
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            vm.saveConduct();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    saveConduct() {
      const vm = this;
      const conduct = {
        canEdit: vm.conduct.canEdit,
        clinic: vm.conduct.clinic,
        commonPrescriptionDrug:
          Object.keys(vm.commonDrugAdd[0]).length === 0
            ? [null]
            : vm.commonDrugAdd.filter((obj) => obj.drug !== undefined),
        daysToReturn: parseInt(vm.inputDayNextSteps, 10),
        doctor: vm.conduct.doctor,
        drugControlledPsychotropic:
          Object.keys(vm.psychotropicControlledDrugsAdd[0]).length === 0
            ? [null]
            : vm.psychotropicControlledDrugsAdd.filter(
              (obj) => obj.drug !== undefined
            ),
        drugControlledRevenue:
          Object.keys(vm.prescriptionDrugsAdd[0]).length === 0
            ? [null]
            : vm.prescriptionDrugsAdd.filter((obj) => obj.drug !== undefined),
        exams: vm.examsAdd[0].id === "" ? [null] : vm.examsAdd,
        generalAttestations: null,
        generalExams: null,
        generalReports: null,
        generalVaccines: null,
        medicalRecommendations: vm.medicalRecommendations,
        nextStep:
          vm.appointment.nextStep !== null &&
          vm.appointment.nextStep !== undefined
            ? vm.appointment.nextStep
            : null,
        nextStepsDoctor: null,
        numDaysReturn: parseInt(vm.inputDayNextSteps, 10),
        paid: vm.paid,
        patientId: vm.attendance.patientId,
        patientName: vm.attendance.patientName,
        payStatus: vm.paid == false ? "gratuito" : "pago",
        proxPasso:
          vm.appointment.nextStep !== null &&
          vm.appointment.nextStep !== undefined
            ? vm.appointment.nextStep.type == "DISCHARGE"
              ? "alta"
              : "retorno"
            : null,

        someExams: vm.someExams,
        someMedicines: vm.someMedicines,
      };
      vm.axios
        .put(
          vm.$store.state.filooServer +
            "medical-records/" +
            vm.$props.medRecId +
            "/conduct",
          conduct,
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            if (vm.clickedSaveChanges === true) {
              vm.showNotifySuccess(
                "Operação realizada",
                "Dados salvos com sucesso",
                4000
              );
              vm.$nextTick(() => {
                vm.$bvModal.show(
                  "medical-care-popup-mc-finalized-instructions"
                );
              });
            } else {
              vm.$bvModal.show("medical-care-popup-mc-finalized-instructions");
            }
          }
        })
        .catch(function (error) {
          vm.showNotifyError(
            "Não foi possível salvar os dados da consulta ",
            6500
          );
          console.error({
            error: error,
            endpoint:
              "medical-records/" + vm.$props.medRecId + "/conduct",
            sent_object: conduct,
            method: "saveConduct",
            http_method: "PUT",
          });
        });
    },
    exit() {
      const vm = this;
      vm.$swal({
        title: "Os dados da consulta que não foram gravados serão perdidos.",
        text: "Confirma a saída do atendimento?",
        icon: "success",

        buttons: ["Não", "Sim"],
      })
        .then(function (value) {
          if (value === true) {
            vm.$router.push("/appointment");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    completeMedRecNoPrint() {
      const vm = this;
      vm.$bvModal.hide("medical-care-popup-mc-finalized-instructions");
      setTimeout(function () {
        vm.$router.push("/dashboard");
      }, 200);
    },
    downloadMedicalRecords() {
      const vm = this;
      vm.axios
        .get(
          vm.$store.state.filooServer +
            "medical-records/" +
            vm.$props.medRecId +
            "/download-token",
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function (response) {
          const dataToken = response.data.token;
          window.open(
            vm.$store.state.filooServer +
              "/medical-records/" +
              vm.$props.medRecId +
              "/complete/download/" +
              dataToken
          );
        })
        .catch(function (error) {
          vm.showNotifyError("Não foi possível realizar o download", 6500);
          console.error({
            error: error,
            endpoint:
              "medical-records/" +
              vm.$props.medRecId +
              "/download-token",
            method: "downloadMedicalRecords",
            http_method: "GET",
          });
        });
    },
    getToken() {
      const vm = this;
      vm.axios
        .get(
          vm.$store.state.filooServer +
            "medical-records/" +
            vm.$props.medRecId +
            "/download-token",
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            vm.token = response.data.token;
          }
        })
        .catch(function (error) {
          vm.showNotifyError(
            "Não foi possível obter o token para realizar downloads",
            6500
          );
          console.error({
            error: error,
            endpoint:
              "medical-records/" +
              vm.$props.medRecId +
              "/download-token",
            method: "getToken",
            http_method: "GET",
          });
        });
    },
    clickButtonPrint(type) {
      const vm = this;
      let url = "";
      switch (type) {
        case "common_medicine":
          url = "/medicine/download/";
          break;
        case "controlled_medicine_old":
          url = "/medicine-control/download/";
          break;
        case "controlled_medicine":
          var urls = [];
          var tempUrl;
          for (var i = 0; i < vm.prescriptionDrugsAdd.length; i++) {
            tempUrl = "/medicine-control/download/" + i;
            console.log("tempUrl: " + tempUrl);
            urls.push(tempUrl);
          }
          for (i = 0; i < vm.psychotropicControlledDrugsAdd.length; i++) {
            tempUrl = "/medicine-psychotropic/download/" + i;
            console.log("tempUrl: " + tempUrl);
            urls.push(tempUrl);
          }

          vm.downloadMedRecUrls(urls);
          return;
        case "exams_and_vaccines":
          url = "/exams-and-vaccines/download/";
          break;
        case "recommendations":
          url = "/general-attestations/download/";
          break;
        case "general_attestations":
          var types = [
            "common_medicine",
            "controlled_medicine",
            "exams-and-vaccines",
            "recommendations",
          ];
          for (let i = 0; i < 4; i++) {
            vm.clickButtonPrint(types[i]);
          }
          break;
        default:
          break;
      }
      vm.axios
        .get(
          vm.$store.state.filooServer +
            "medical-records/" +
            vm.$props.medRecId +
            url +
            vm.token,
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function () {
          window.open(
            vm.$store.state.filooServer +
              "medical-records/" +
              vm.$props.medRecId +
              url +
              vm.token
          );
        })
        .catch(function (error) {
          vm.showNotifyError("Não foi realizar o download", 6500);
          console.error({
            error: error,
            endpoint:
              "medical-records/" + vm.$props.medRecId + url + vm.token,
            method: "clickButtonPrint",
            http_method: "GET",
          });
        });
    },
    downloadMedRecUrls(urls) {
      const vm = this;
      let i = 0;
      for (const urlIndividual of urls) {
        vm.axios
          .get(
            vm.$store.state.filooServer +
              "medical-records/" +
              vm.$props.medRecId +
              urlIndividual +
              "/" +
              vm.token,
            {
              responseType: "arraybuffer",
              headers: {
                Authorization: vm.$session.get("bearer"),
                "Content-Type": "application/json",
                Accept: "application/pdf",
              },
            }
          )
          .then(function (response) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              vm.patientHealthData.patientName + " - " + i++ + ".pdf"
            );
            document.body.appendChild(link);
            link.click();
          })
          .catch(function (error) {
            vm.showNotifyError("Não foi possível obter as urls para download", 6500);
            console.error({
              error: error,
              endpoint:
                "medical-records/" +
                vm.$props.medRecId +
                urlIndividual +
                "/" +
                vm.token,
              method: "downloadMedRecUrls",
              http_method: "GET",
            });
          });
      }
    },
    calculateIMC() {
      const vm = this;
      if (vm.appointment.weight && vm.appointment.height) {
        const imc =
          vm.appointment.weight /
          (vm.appointment.height * vm.appointment.height);
        vm.appointment.imc = imc.toFixed(2);
      } else {
        vm.appointment.imc = null;
      }
    },
    selectNextStep(nextStep) {
      const vm = this;
      const dias = nextStep;
      let json = {};
      const description = "Retorno em " + dias + " dias";
      if (nextStep == 0) {
        json = {
          id: null,
          amountDay: null,
          amountDayUntilReturn: null,
          description: "Alta",
          type: "DISCHARGE",
        };
      } else if (nextStep < 31) {
        json = {
          id: "1",
          amountDay: dias,
          amountDayUntilReturn: null,
          description: description,
          type: "RETURN",
        };
      } else if (nextStep < 61) {
        json = {
          id: "2",
          amountDay: dias,
          amountDayUntilReturn: null,
          description: description,
          type: "RETURN",
        };
      } else {
        json = {
          id: "3",
          amountDay: dias,
          amountDayUntilReturn: null,
          description: description,
          type: "RETURN",
        };
      }
      vm.appointment.nextStep = json;
    },
    showPagePatientData() {
      const vm = this;
      vm.$emit("showPagePatientData", event);
    },
    getPatientHealthData() {
      const vm = this;
      vm.axios
        .get(
          vm.$store.state.filooServer +
            "medical-records/" +
            vm.$props.medRecId +
            "/health-data",
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            vm.patientHealthData = response.data;
          }
        })
        .catch(function (error) {
          vm.showNotifyError("Não foi possível obter os dados de saúde do paciente", 6500);
          console.error({
            error: error,
            endpoint:
              "medical-records/" + vm.$props.medRecId + "/health-data",
            method: "getPatientHealthData",
            http_method: "GET",
          });
        });
    },
    getConduct() {
      const vm = this;
      vm.axios
        .get(
          vm.$store.state.filooServer +
            "medical-records/" +
            vm.$props.medRecId +
            "/conduct",
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            vm.conduct = response.data;
            vm.canEdit =
              vm.$props.justSeePerformed === true ? false : vm.conduct.canEdit;
            vm.someMedicines = vm.conduct.someMedicines;
            vm.medicalRecommendations = vm.conduct.medicalRecommendations;
            vm.paid = vm.conduct.payStatus == "pago"? true: false;
            vm.patientDischarge = vm.conduct.nextStep.type === "DISCHARGE" ? true : false;
            vm.patientReturn = vm.conduct.nextStep.type === "RETURN" ? true : false;
            vm.inputDayNextSteps = vm.conduct.daysToReturn;
            if (vm.conduct.exams) {
              if (vm.conduct.exams.length > 0) {
                vm.examRequestShow = true;
                vm.fillExams(vm.conduct.exams);
              }
            }
            if (vm.testNullUndefinedEmpty(vm.someMedicines) === false) {
              vm.prescriptionDrugsShow = true;
            }
            if (vm.conduct.drugControlledPsychotropic) {
              if (vm.conduct.drugControlledPsychotropic.length > 0) {
                vm.prescriptionDrugsShow = true;
                vm.fillDrugControlledPsychotropic(
                  vm.conduct.drugControlledPsychotropic
                );
              }
            }
            if (vm.conduct.commonPrescriptionDrug) {
              if (vm.conduct.commonPrescriptionDrug.length > 0) {
                vm.prescriptionDrugsShow = true;
                vm.fillCommonPrescriptionDrug(
                  vm.conduct.commonPrescriptionDrug
                );
              }
            }
            if (vm.conduct.drugControlledRevenue) {
              if (vm.conduct.drugControlledRevenue.length > 0) {
                vm.prescriptionDrugsShow = true;
                vm.fillDrugControlledRevenue(vm.conduct.drugControlledRevenue);
              }
            }
          }
        })
        .catch(function (error) {
          vm.showNotifyError("Não foi possível obter os dados de conduta", 6500);
          console.error({
            error: error,
            endpoint:
              "medical-records/" + vm.$props.medRecId + "/conduct",
            method: "getConduct",
            http_method: "GET",
          });
        });
    },
    fillExams(exams) {
      const vm = this;
      let index = 0;
      for (index = 0; index < exams.length; index++) {
        vm.examsAdd[index] = exams[index];
        vm.exams[index] = exams[index];
      }
    },
    fillDrugControlledPsychotropic(drugControlledPsychotropic) {
      const vm = this;
      let index = 0;
      for (index = 0; index < drugControlledPsychotropic.length; index++) {
        vm.psychotropicControlledDrugsAdd[index] =
          drugControlledPsychotropic[index];
        vm.controlledDrugs[index] = drugControlledPsychotropic[index].drug;
      }
    },
    fillCommonPrescriptionDrug(commonPrescriptionDrug) {
      const vm = this;
      let index = 0;
      for (index = 0; index < commonPrescriptionDrug.length; index++) {
        vm.commonDrugAdd[index] = commonPrescriptionDrug[index];
        vm.commonDrugs[index] = commonPrescriptionDrug[index].drug;
      }
    },
    fillDrugControlledRevenue(drugControlledRevenue) {
      const vm = this;
      let index = 0;
      for (index = 0; index < drugControlledRevenue.length; index++) {
        vm.prescriptionDrugsAdd[index] = drugControlledRevenue[index];
        vm.prescriptionDrugs[index] = drugControlledRevenue[index].drug;
      }
    },
    getRegistrationData() {
      const vm = this;
      vm.axios
        .get(
          vm.$store.state.filooServer +
            "medical-records/" +
            vm.$props.medRecId +
            "/registration-data",
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            vm.registrationData = response.data;
          }
        })
        .catch(function (error) {
          vm.showNotifyError(
            "Não foi possível obter os dados de registro do paciente",
            6500
          );
          console.error({
            error: error,
            endpoint:
              "medical-records/" +
              vm.$props.medRecId +
              "/registration-data",
            method: "getRegistrationData",
            http_method: "GET",
          });
        });
    },
    getAttendance() {
      const vm = this;
      vm.axios
        .get(
          vm.$store.state.filooServer +
            "medical-records/" +
            vm.$props.medRecId +
            "/attendance",
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            vm.attendance = response.data;
            vm.someExams = vm.attendance.someExams;
            vm.canEdit =
              vm.$props.justSeePerformed === true
                ? false
                : vm.attendance.canEdit;
            if (vm.someExams !== null) {
              vm.examRequestShow = true;
            }
            vm.mainComplaint = vm.attendance.mainComplaint;
            vm.consultationDuration = vm.attendance.duration;
            vm.clinicalExam = vm.attendance.clinicalExam;
            vm.fillDiagnostics(vm.attendance.diagnostics);
          }
        })
        .catch(function (error) {
          vm.showNotifyError(
            "Não foi possível obter os dados de atendimento do paciente",
            6500
          );
          console.error({
            error: error,
            endpoint:
              "medical-records/" + vm.$props.medRecId + "/attendance",
            method: "getAttendance",
            http_method: "GET",
          });
        });
    },
    fillDiagnostics(diagnostics) {
      const vm = this;

      let index = 0;
      for (index = 0; index < diagnostics.length; index++) {
        vm.diagnosticHypothesisAdd[index] = diagnostics[index];
        vm.diagnosticHypothesis[index] = diagnostics[index];
      }
    },
    getDiagnosticHypothesis() {
      const vm = this;
      vm.axios
        .get(vm.$store.state.filooServer + "diagnosis", {
          headers: { Authorization: vm.$session.get("bearer") },
        })
        .then(function (response) {
          if (response.status === 200) {
            vm.diagnosticHypothesis = response.data.content;
          }
        })
        .catch(function (error) {
          vm.showNotifyError("Não foi possível obter dados de hipótese diagnóstica ", 6500);
          console.error({
            error: error,
            endpoint: "diagnosis",
            method: "getDiagnosticHypothesis",
            http_method: "GET",
          });
        });
    },
    getMedicalRecordStatus() {
      const vm = this;
      vm.axios
        .get(vm.$store.state.filooServer + "medical-records/status", {
          headers: { Authorization: vm.$session.get("bearer") },
        })
        .then(function (response) {
          if (response.status === 200) {
            vm.status = response.data;
          }
        })
        .catch(function (error) {
          vm.showNotifyError("Não foi possível obter o status dos Registros Médicos", 6500);
          console.error({
            error: error,
            endpoint: "medical-records/status",
            method: "getMedicalRecordStatus",
            http_method: "GET",
          });
        });
    },
    addExam() {
      const vm = this;
      vm.examsAdd.push([]);
    },
    removeExam(index) {
      const vm = this;
      vm.examsAdd.splice(index, 1);
    },
    addCommonDrug() {
      const vm = this;
      vm.commonDrugAdd.push({});
    },
    removeCommonDrug(index) {
      const vm = this;
      vm.commonDrugAdd.splice(index, 1);
    },
    addControlledDrugs() {
      const vm = this;
      vm.psychotropicControlledDrugsAdd.push({});
    },
    removeControlledDrugs(index) {
      const vm = this;
      vm.psychotropicControlledDrugsAdd.splice(index, 1);
    },
    addPrescriptionDrugs() {
      const vm = this;
      vm.prescriptionDrugsAdd.push({});
    },
    removePrescriptionDrugs(index) {
      const vm = this;
      vm.prescriptionDrugsAdd.splice(index, 1);
    },
    addDiagnosticHypothesis() {
      const vm = this;
      vm.diagnosticHypothesisAdd.push([]);
    },
    removeDiagnosticHypothesis(index) {
      const vm = this;
      vm.diagnosticHypothesisAdd.splice(index, 1);
    },
    getExams() {
      const vm = this;
      vm.axios
        .get(vm.$store.state.filooServer + "exams", {
          headers: { Authorization: vm.$session.get("bearer") },
        })
        .then(function (response) {
          if (response.status === 200) {
            vm.exams = response.data;
          }
        })
        .catch(function (error) {
          vm.showNotifyError("Não foi possível obter os exames", 4500);
          console.error({
            error: error,
            endpoint: "exams",
            method: "getExams",
            http_method: "GET",
          });
        });
    },
    isEmpty(obj) {
      // null é "empty"
      if (obj == null) return true;

      // Suponhamos que se tenha uma propriedade length com um valor diferente de zero
      // Essa proriedade será verdadeira
      if (obj.length > 0) return false;
      if (obj.length === 0) return true;

      // Caso contrário ela tem todas as sua propriedades?
      // Isto não se manipula
      // toString e valueOf são erros de enumeração no IE < 9
      for (const key in obj) {
        if (hasOwnProperty.call(obj, key)) return false;
      }
      return true;
    },
    sendFiles() {
      const vm = this;
      vm.files = [];
      vm.formData = "";
      vm.typeEmailRadio = null;
      vm.$bvModal.show("medical-care-popup-sent-files");
    },
    addFile(e) {
      console.log("addFile");
      const vm = this;
      const droppedFiles = e.dataTransfer.files;
      let doUpload = false;
      const extAllowed = ["jpg", "pdf"];
      if (!droppedFiles) return;
      [...droppedFiles].forEach((f) => {
        if (f.size > 10000000) {
          vm.showNotifyWarning(
            "O arquivo <b><i>" +
              f.name +
              "</i></b> passou o tamanho permitido de 10MB.",
            4000
          );
          return (doUpload = false);
        }
        var extFile = f.name.split(".").pop();
        if (
          typeof extAllowed.find(function (ext) {
            return extFile == ext;
          }) == "undefined"
        ) {
          vm.showNotifyWarning(
            "O arquivo <b><i>" +
              f.name +
              "</i></b> possui a extensão <b></i>" +
              extFile +
              "</i></b>não permitida.",
            4000
          );
          return (doUpload = false);
        } else {
          doUpload = true;
          vm.files.push(f);
        }
      });

      if (droppedFiles.length > 0 && doUpload === true) {
        for (let i = 0; i < droppedFiles.length; i++) {
          const file = droppedFiles[i];
          console.log("droppedFiles");
          console.log(droppedFiles);

          const formData = new FormData();

          formData.append("file", file);
          console.log(formData);
        }
      }
    },
    clickAddFile: function (e) {
      const vm = this;
      var input = e.target;
      var extAllowed = ["jpg", "pdf"];
      var extFile = input.value.split(".").pop();

      if (input.files[0].size > 10000000) {
        vm.showNotifyWarning(
          "O arquivo <b><i>" +
            input.files[0].name +
            "</i></b> passou o tamanho permitido de 10MB.",
          4000
        );
      }
      if (
        typeof extAllowed.find(function (ext) {
          return extFile == ext;
        }) == "undefined"
      ) {
        vm.showNotifyWarning(
          "O arquivo <b><i>" +
            input.files[0].name +
            "</i></b> possui a extensão <b></i>" +
            extFile +
            "</i></b>não permitida.",
          4000
        );
      } else {
        if (input.files && input.files[0]) {
          vm.files.push(input.files[0]);
        }
      }
      console.log("vm.files apos add: ");
      console.log(vm.files);
    },
    removeFile(file, indextr) {
      const vm = this;
      vm.files.splice(indextr, 1);
    },
    enviaEmail() {
      const vm = this;
      vm.formData = new FormData();
      if (vm.testNullUndefinedEmpty(vm.typeEmailRadio)) {
        vm.showNotifyWarning("Selecione o tipo de mensagem.", 4000);
      } else if (Array.isArray(vm.formData) && vm.formData.length) {
        vm.showNotifyWarning("Adicione ao menos um arquivo.", 4000);
      } else {
        for (var i = 0; i < vm.files.length; i++) {
          vm.formData.append("file", vm.files[i], vm.files[i].name);
        }
        vm.axios
          .post(
            vm.$store.state.filooServer +
              "doctor/documents/sendDocuments/" +
              vm.registrationData.patientId +
              "/" +
              vm.typeEmailRadio,
            vm.formData,
            {
              headers: { Authorization: vm.$session.get("bearer") },
              "Content-Type": "multipart/form-data",
            }
          )
          .then(function () {
            vm.showNotifySuccess(
              "Operação realizada",
              "Mensagem enviada com sucesso!",
              3000
            );
            vm.$nextTick(() => {
              vm.$bvModal.hide("medical-care-popup-sent-files");
              vm.files = [];
              vm.formData = "";
            });
          })
          .catch(function (error) {
            console.error({
              error: error,
              method: "enviaEmail",
              endpoint:
                "doctor/documents/sendDocuments/" +
                vm.registrationData.patientId +
                "/" +
                vm.typeEmailRadio,
              http_method: "POST",
              sent_object: vm.formData,
            });
            vm.showNotifyError(
              "Não foi possível anexar o arquivo desejado",
              6000
            );
            vm.$bvModal.hide("medical-care-popup-sent-files");
            vm.files = [];
            vm.formData = "";
          });
      }
    },
    nextPageCommon() {
      const vm = this;
      if (vm.pageCommon < vm.totalPagesCommon) {
        vm.pageCommon++;
        vm.getMedicinesCommon();
      }
    },
    lastPageCommon() {
      const vm = this;
      if (vm.pageCommon > 0) {
        vm.pageCommon--;
        vm.getMedicinesCommon();
      }
    },
    nextPagePrescription() {
      const vm = this;
      if (vm.pagePrescription < vm.totalPagesPrescription) {
        vm.pagePrescription++;
        vm.getMedicinesPrescription();
      }
    },
    lastPagePrescription() {
      const vm = this;
      if (vm.pagePrescription > 0) {
        vm.pagePrescription--;
        vm.getMedicinesPrescription();
      }
    },
    nextPageControlled() {
      const vm = this;
      if (vm.pagePrescription < vm.totalPagesPrescription) {
        vm.pageControlled++;
        vm.getMedicinesControlled();
      }
    },
    lastPageControlled() {
      const vm = this;
      if (vm.pageControlled > 0) {
        vm.pageControlled--;
        vm.getMedicinesControlled();
      }
    },
    findCommon(search) {
      const vm = this;
      vm.pageCommon = 0;
      vm.searchCommon = search;
      vm.getMedicinesCommon();
    },
    findPrescription(search) {
      const vm = this;
      vm.pagePrescription = 0;
      vm.searchPrescription = search;
      vm.getMedicinesPrescription();
    },
    findControlled(search) {
      const vm = this;
      vm.pageControlled = 0;
      vm.searchControlled = search;
      vm.getMedicinesControlled();
    },
    getMedicinesCommon() {
      const vm = this;
      vm.commonDrugsIsLoading = true;
      vm.axios
        .get(
          vm.$store.state.filooServer +
            "medicines?page=" +
            vm.pageCommon +
            "&search=" +
            vm.searchCommon +
            "&size=10&sort=product,asc",
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            vm.commonDrugs = response.data.content;
            vm.totalPagesCommon = response.data.totalPages - 1;
          }
        })
        .catch(function (error) {
          vm.showNotifyError(
            "Não foi possível obter os dados dos medicamentos de uso comum",
            6500
          );
          console.error({
            error: error,
            endpoint:
              "medicines?page=" +
              vm.pageCommon +
              "&search=" +
              vm.searchCommon +
              "&size=10&sort=product,asc",
            method: "getMedicinesCommon",
            http_method: "GET",
          });
        })
        .finally(function () {
          vm.commonDrugsIsLoading = false;
        });
    },
    getMedicinesPrescription() {
      const vm = this;
      vm.prescriptionDrugsIsLoading = true;
      vm.axios
        .get(
          vm.$store.state.filooServer +
            "medicines?page=" +
            vm.pagePrescription +
            "&search=" +
            vm.searchPrescription +
            "&size=10&sort=product,asc",
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            vm.prescriptionDrugs = response.data.content;
            vm.totalPagesPrescription = response.data.totalPages - 1;
          }
        })
        .catch(function (error) {
          vm.showNotifyError(
            "Não foi possível obter os dados dos medicamentos de receita controlada",
            6500
          );
          console.error({
            error: error,
            endpoint:
              "medicines?page=" +
              vm.pagePrescription +
              "&search=" +
              vm.searchPrescription +
              "&size=10&sort=product,asc",
            http_method: "GET",
          });
        })
        .finally(function () {
          vm.prescriptionDrugsIsLoading = false;
        });
    },
    getMedicinesControlled() {
      const vm = this;
      vm.controlledDrugsIsLoading = true;
      vm.axios
        .get(
          vm.$store.state.filooServer +
            "medicines?page=" +
            vm.pageControlled +
            "&search=" +
            vm.searchControlled +
            "&size=10&sort=product,asc",
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            vm.controlledDrugs = response.data.content;
            vm.totalPagesControlled = response.data.totalPages - 1;
          }
        })
        .catch(function (error) {
          vm.showNotifyError(
            "Não foi possível obter os dados dos medicamentos de receita controlada psicotrópica",
            7500
          );
          console.error({
            error: error,
            endpoint:
              "medicines?page=" +
              vm.pageControlled +
              "&search=" +
              vm.searchControlled +
              "&size=10&sort=product,asc",
            http_method: "GET",
          });
        })
        .finally(function () {
          vm.controlledDrugsIsLoading = false;
        });
    },
    commonLabel({ product, presentation }) {
      return `${product} – ${presentation}`;
    },
    goToPatientData() {
      const vm = this;
      vm.$emit("goBackToPatientData");
    },
    goToHistoric() {
      const vm = this;
      vm.$emit("goToHistoric");
    },
  },
};
</script>
<style lang="scss" src="./medicalCare.scss">
</style>
