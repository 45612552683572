<template src="./videoCallMedicalAssistance.html"></template>
<script>
import mixins from "../../../../mixins/mixins.js";
// https://github.com/MatheusrdSantos/vue-quick-chat
import { Chat } from "vue-quick-chat";
import "vue-quick-chat/dist/vue-quick-chat.css";
export default {
  mixins: [mixins],
  name: "video-call",
  props: {
    medRecId: Number,
    justSeeNew: Boolean,
    justSeePerformed: Boolean,
    medicalCareType: String,
  },
  components: {
    Chat,
  },
  data() {
    return {
      popupCamChrome: false,
      popupCamChromeMobile: false,
      //VÍDEO ------------------------------------------------------------------>
      videoEnabled: false,
      audioEnabled: false,
      roomUrl: undefined,
      numSize: 12,
      insideTheRoom: false,
      accessCam: false,
      accessMic: false,
      isChrome: undefined,
      popupCamFirefox: false,
      supportsTouch: undefined,
      //CHAT ------------------------------------------------------------------>
      visible: true,
      participants: [
        {
          name: "Paciente",
          id: null,
          profilePicture:
            "https://upload.wikimedia.org/wikipedia/en/thumb/a/a1/NafSadh_Profile.jpg/768px-NafSadh_Profile.jpg",
        },
      ],
      myself: {
        name: "Profissional da saúde",
        id: null,
        profilePicture:
          "https://lh3.googleusercontent.com/-G1d4-a7d_TY/AAAAAAAAAAI/AAAAAAAAAAA/AAKWJJPez_wX5UCJztzEUeCxOd7HBK7-jA.CMID/s83-c/photo.jpg",
      },
      messages: [],
      chatTitle: "Sala de atendimento",
      placeholder: "Escreva e envie sua mensagem",
      colors: {
        header: {
          bg: "#508cc8",
          text: "#fff",
        },
        message: {
          myself: {
            bg: "#fff",
            text: "#bdb8b8",
          },
          others: {
            bg: "#508cc8",
            text: "#fff",
          },
          messagesDisplay: {
            bg: "#f7f3f3",
          },
        },
        submitIcon: "#508cc8",
        submitImageIcon: "#508cc8",
      },
      borderStyle: {
        topLeft: "10px",
        topRight: "10px",
        bottomLeft: "10px",
        bottomRight: "10px",
      },
      hideCloseButton: true,
      submitIconSize: 25,
      closeButtonIconSize: "20px",
      asyncMode: false,
      toLoad: [],
      scrollBottom: {
        messageSent: true,
        messageReceived: false,
      },
      displayHeader: true,
      profilePictureConfig: {
        others: false,
        myself: false,
        styles: {
          width: "30px",
          height: "30px",
          borderRadius: "50%",
        },
      },
      timestampConfig: {
        format: "HH:mm",
        relative: false,
      },
      // there are other options, you can check them here
      // https://soapbox.github.io/linkifyjs/docs/options.html
      linkOptions: {
        myself: {
          className: "myLinkClass",
          events: {
            click: function () {
              alert("Link clicked!");
            },
            mouseover: function () {
              alert("Link hovered!");
            },
          },
          format: function (value, type) {
            if (type === "url" && value.length > 50) {
              value = value.slice(0, 50) + "…";
            }
            return value;
          },
        },
        others: {
          className: "othersLinkClass",
          events: {
            click: function () {
              alert("Link clicked!");
            },
            mouseover: function () {
              alert("Link hovered!");
            },
          },
          format: function (value, type) {
            if (type === "url" && value.length > 50) {
              value = value.slice(0, 50) + "…";
            }
            return value;
          },
        },
      },
    };
  },
  created() {
    const vm = this;
    if (
      vm.$props.justSeeNew === false &&
      vm.$props.justSeePerformed === false &&
      vm.$props.medicalCareType === "video"
    ) {
      vm.getRoom();
      vm.addEventListener();
    }
  },
  mounted() {
    const vm = this;
    vm.isChrome =
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    if (
      vm.$props.justSeeNew === false &&
      vm.$props.justSeePerformed === false &&
      vm.$props.medicalCareType === "video"
    ) {
      vm.init();
    }
  },
  destroyed() {
    const vm = this;
    vm.roomUrl = "about:blank";
    window.removeEventListener("resize", vm.handleResize);
  },
  beforeRouteLeave(to, from, next) {
    const vm = this;
    vm.roomUrl = "https://webrtc.filoo.com.br";
    vm.exitRoom();
    next();
  },
  methods: {
    init() {
      const vm = this;
      vm.checksBrowserPermissions();
      vm.sendLogRTC(
        "Medico => isChrome: " + vm.isChrome + " vm.accessCam: " + vm.accessCam
      );
      if (
        vm.$props.justSeeNew === false &&
        vm.$props.justSeePerformed === false
      ) {
        vm.enteringTheRoom();
      }
    },
    checksBrowserPermissions() {
      const vm = this;
      navigator.permissions
        .query({ name: "microphone" })
        .then((permissionObj) => {
          console.log("isChrome permissionObj mic succes");
          console.log(permissionObj.state);
          // prompt quer dizer que ainda vai perguntar
          // granted quer dizer que tem acesso
          // denied quer dizer que tem nao acesso
          if (permissionObj.state === "granted") {
            vm.accessMic = true;
          } else {
            vm.accessMic = false;
          }
        })
        .catch((error) => {
          console.log("isChrome permissionObj mic error");
          console.log("Got error :", error);
        });

      navigator.permissions
        .query({ name: "camera" })
        .then((permissionObj) => {
          console.log("isChrome permissionObj cam succes");
          console.log(permissionObj.state);
          if (permissionObj.state === "granted") {
            vm.accessCam = true;
            vm.enteringTheRoom();
          } else {
            vm.accessCam = false;
          }
        })
        .catch((error) => {
          console.log("isChrome permissionObj cam error");
          console.log("Got error :", error);
        });

      // Verficia se esta rodando no browser ou pwa
      console.log("window.matchMedia: ");
      if (window.matchMedia("(display-mode: standalone)").matches) {
        console.log("This is running as standalone.");
      }
      if (window.matchMedia("(display-mode: fullscreen)").matches) {
        console.log("This is running as fullscreen.");
      }
      if (window.matchMedia("(display-mode: minimal-ui)").matches) {
        console.log("This is running as minimal-ui.");
      }
      if (window.matchMedia("(display-mode: browser)").matches) {
        console.log("This is running as browser.");
      }
    },
    getRoom() {
      const vm = this;
      vm.axios
        .get(
          vm.$store.state.filooServer +
            "appointments/room/" +
            vm.$props.medRecId,
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            console.log(response);
            var responseRoom = response.data;
            if (response.data[0] === null) {
              vm.roomUrl = undefined;
            } else {
              if (vm.canEdit == false) {
                vm.roomUrl = undefined;
              } else {
                vm.roomUrl = "https://webrtc.filoo.com.br/#" + responseRoom[0]; //+ "?" + responseRoom[1];
                vm.numSize = 8;
              }
            }
            console.log("responseRoom");
            console.log(responseRoom);
            console.log("vm.roomUrl: ");
            console.log(vm.roomUrl);
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      vm.axios.put(
        vm.$store.state.filooServer +
          "appointments/room/doctor_inroom/" +
          vm.$props.medRecId +
          "/1",
        {},
        {
          headers: { Authorization: vm.$session.get("bearer") },
        }
      );
    },
    enteringTheRoom() {
      const vm = this;
      if (vm.isChrome && vm.getChromeVersion() < 60) {
        vm.$swal({
          text:
            "A sua versão do navegador é um pouco antiga e pode encontrar problemas.",
          icon: "warning",
        });
      }
      vm.axios
        .put(
          vm.$store.state.filooServer +
            "appointments/room/doctor_inroom/" +
            vm.$props.medRecId +
            "/1",
          {},
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            vm.insideTheRoom = true;
            vm.sendRtcToken(vm.$props.medRecId);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    sendRtcToken(medRecId) {
      const vm = this;
      setTimeout(function () {
        // precisa dar tempo para a pagina webrtc iniciar
        vm.axios
          .get(
            vm.$store.state.filooServer +
              "appointments/getSessionToken/" +
              medRecId,
            {
              headers: {
                Authorization: vm.$session.get("bearer"),
              },
            }
          )
          .then(function (response) {
            console.log({ sendRtcToken: response });
            const x = window.document.getElementById("webtrcframe");
            const y = x.contentWindow || x.contentDocument;
            const messageObject = ["rtcToken", response.data];
            console.log("messageObject");
            console.log(messageObject);
            y.postMessage(messageObject, "*");
          });
      }, 4000);
    },
    askForCamera() {
      const vm = this;
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(function (response) {
          console.log("navigator.mediaDevices.getUserMedia response: ");
          console.log(response); //response.active == true  tem acesso
          if (response.active === true) {
            vm.accessMic = true;
            vm.accessCam = true;
          } else {
            vm.accessMic = false;
            vm.accessCam = false;
          }
        })
        .catch(function (error) {
          vm.accessMic = false;
          vm.accessCam = false;
          console.log("navigator.mediaDevices.getUserMedia error: ");
          console.dir(error);
          vm.supportsTouch =
            "ontouchstart" in window || navigator.msMaxTouchPoints;

          //'DOMException: Requested device not found' caso nao tenha
          //'DOMException: Permission denied' caso bloqueado
          if (error.name === "NotAllowedError") {
            //"PermissionDeniedError" -> firefox
            console.log("permission denied");
            if (vm.supportsTouch) {
              vm.$bvModal.show("video-call-popup-chrome-mobile");
            } else {
              vm.$bvModal.show("video-call-popup-chrome");
            }
          }
          if (error.name === "NotFoundError") {
            console.log("not found");
            vm.$bvModal.show("video-call-popup-cam-not-found");
          }
          vm.sendLogRTC(
            "Medico => askForCamera error: " +
              error.name +
              " - " +
              error.message
          );
        });
    },
    sendLogRTC(mensagem) {
      const vm = this;
      vm.axios
        .post(
          vm.$store.state.filooServer +
            "appointments/room/logRoom/" +
            vm.$props.medRecId,
          {
            msg: mensagem,
          },
          {
            headers: {
              Authorization: vm.$session.get("bearer"),
            },
          }
        )
        .then(function (response) {
          console.log("sendLogRTC");
          console.log(response);
        })
        .catch(function (error) {
          console.log("sendLogRTC error");
          console.log(error);
        });
    },
    muteVideo() {
      const vm = this;
      const x = window.document.getElementById("webtrcframe");
      const y = x.contentWindow || x.contentDocument;
      y.postMessage("muteVideoMsg", "*");
      vm.videoEnabled = !vm.videoEnabled;
    },

    muteAudio() {
      const vm = this;
      const x = window.document.getElementById("webtrcframe");
      const y = x.contentWindow || x.contentDocument;
      y.postMessage("muteAudioMsg", "*");
      vm.audioEnabled = !vm.audioEnabled;
    },
    //CHAT------------------------------------------------------------------>
    addEventListener() {
      const vm = this;
      window.addEventListener("resize", vm.handleResize);
      window.addEventListener("beforeunload", vm.removeUser);
      window.addEventListener("onCloseWindow", vm.removeUser);
      ///////////////CHAT//////////////////////////////////////////////////
      if (window.addEventListener) {
        window.addEventListener(
          "message",
          function (result) {
            const resultado = result;
            console.log("Listening...");
            console.log("A MENSAGEM CHEGOU NO APP.JS addEventListener ");
            console.log(resultado);
            if (resultado.data[0] === "mensagemChatInDoc") {
              console.log("insere no componente chat");
              console.log(resultado.data[1]);
              vm.getMessageVidu(resultado.data[1]);
            }
          },
          false
        );
      } else {
        window.attachEvent(
          "onmessage",
          function (result) {
            const resultado = result;
            console.log("Listening...");
            console.log("A MENSAGEM CHEGOU NO APP.JS attachEvent ");
            console.log(resultado);
          },
          false
        );
      }
    },
    onType: function () {},
    loadMoreMessages(resolve) {
      setTimeout(() => {
        resolve(this.toLoad);
        this.messages.unshift(...this.toLoad);
        this.toLoad = [];
      }, 1000);
    },
    onMessageSubmit: function (message) {
      const vm = this;
      vm.messages.push(message);
      setTimeout(() => {
        message.uploaded = true;
        vm.sendMessageVidu(message);
      }, 2000);
    },
    onClose() {
      const vm = this;
      vm.visible = false;
    },
    sendMessageVidu(mensagem) {
      const vm = this;
      const x = window.document.getElementById("webtrcframe");
      const y = x.contentWindow || x.contentDocument;
      const mensagemObj = {
        author: vm.$session.get("name"),
        type: "text",
        data: {
          author: vm.$session.get("name"),
          text: mensagem.content,
          content: mensagem.content,
          myself: true,
          participantId: 3,
          timestamp: mensagem.timestamp,
          uploaded: mensagem.uploaded,
          viewed: mensagem.viewed,
        },
      };
      const messageObject = ["mensagemChatFromDoctor", mensagemObj];
      console.log({ sendMessageVidu: mensagem });
      y.postMessage(messageObject, "*");
    },

    getMessageVidu(eventListenerObj) {
      const vm = this;
      const msgTxt = eventListenerObj;
      const now = new Date();
      const msg = {
        author: "user1",
        type: "text",
        name: "user1",
        content: msgTxt,
        myself: false,
        participantId: null,
        timestamp: {
          year: now.getFullYear(),
          month: now.getMonth(),
          day: now.getDate(),
          hour: now.getHours(),
          minute: now.getMinutes(),
          second: now.getSeconds(),
          millisecond: now.getMilliseconds(),
        },
        data: {
          text: msgTxt,
        },
      };
      vm.messages = [...vm.messages, msg];
    },
  },
};
</script>

<style lang="scss" src="./videoCallMedicalAssistance.scss">
</style>