import { render, staticRenderFns } from "./videoCallMedicalAssistance.html?vue&type=template&id=a8affd86&external"
import script from "./videoCallMedicalAssistance.vue?vue&type=script&lang=js"
export * from "./videoCallMedicalAssistance.vue?vue&type=script&lang=js"
import style0 from "./videoCallMedicalAssistance.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports