<template src="./patientData.html" />

<script>
import { useStore } from "@/store/store.js"
import moment from "moment"
import mixins from "@/mixins/mixins.js"

export default {
  name: "PatientData",
  mixins: [mixins],
  props: {
    medRecId: Number,
    justSeeNew: Boolean,
    justSeePerformed: Boolean,
    medicalCareType: String,
    patientId: Number,
  },

  data() {
    return {
      genderOptions: [
        { name: "Feminino", value: "FEMALE" },
        { name: "Masculino", value: "MALE" },
      ],
      genderPatient: null,
      videoEnabled: true,
      audioEnabled: true,
      maritalStatus: [],
      maritalStatusPatient: null,
      educationalAttainmentsPatient: "",
      educationalAttainments: [],
      address: {
        id: "",
        zipcode: "",
        city: "",
        complement: "",
        neighborhood: "",
        state: "",
        street: "",
        number: "",
      },
      firstAppointmentDateUsFormat: "",
      depression: [
        { text: "Sim", value: true },
        { text: "Não", value: false },
      ],
      hospitalizations: [],
      previousDiseases: [],
      surgeries: [],
      idPatient: 0,
      continuousMedicationUse: [],
      cancerHistory: [],
      dataEntered: [],
      smoking: null,
      showPatientData: true,
      showPatientAttendance: false,
      showPatientHistoric: false,
      height: null,
      weight: null,
      arterialPressure: null,
      abdominalCircumference: null,
      glycemia: null,
      alcoholism: null,
      drugs: null,
      quantitySmoking: null,
      physicalActivity: null,
      someoneSystemicArterialHypertension: null,
      someoneAcuteMyocardialInfarction: null,
      someoneDementiaSyndrome: null,
      someoneDiabetesMelitus: null,
      someoneStroke: null,
      someoneDepression: null,
      canEdit: false,
      openCollapseRegistrationData: true,
      dateBirth: null,
      disabledFieldStreet: true,
    };
  },

  setup () {
    const store = useStore()

    return {
      getAddressByZipcode: (cep) => store.dispatch("getAddressByZipcode", cep),
    }
  },

  mounted () {
    const vm = this;
    vm.init();
  },

  methods: {
    init() {
      const vm = this;
      vm.addHospitalization();
      vm.addPreviusDiase();
      vm.addSurgery();
      vm.addCancerHistory();
      vm.addContinuousMedicationUse();
      vm.getRegistrationData();
      vm.getMaritalStatus();
      vm.getEducationalAttaiments();
      vm.getHealthData();
    },
    addHospitalization() {
      const vm = this;
      vm.hospitalizations.push("");
    },
    removeHospitalization(index) {
      const vm = this;
      vm.hospitalizations.splice(index, 1);
    },
    addPreviusDiase() {
      const vm = this;
      vm.previousDiseases.push("");
    },
    removePreviusDiase(index) {
      const vm = this;
      vm.previousDiseases.splice(index, 1);
    },
    addSurgery() {
      const vm = this;
      vm.surgeries.push("");
    },
    removeSurgery(index) {
      const vm = this;
      vm.surgeries.splice(index, 1);
    },
    addContinuousMedicationUse() {
      const vm = this;
      vm.continuousMedicationUse.push("");
    },
    removeContinuousMedicationUse(index) {
      const vm = this;
      vm.continuousMedicationUse.splice(index, 1);
    },
    addCancerHistory() {
      const vm = this;
      vm.cancerHistory.push("");
    },
    removeCancerHistory(index) {
      const vm = this;
      vm.cancerHistory.splice(index, 1);
    },
    getHealthData() {
      const vm = this;
      vm.axios
        .get(
          vm.$store.state.filooServer +
            "medical-records/" +
            vm.$props.medRecId +
            "/health-data",
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            vm.fillPersonalbackground(response.data);
            vm.fillFamilyHistory(response.data);
            vm.smoking = response.data.smoking;
            vm.height = response.data.height;
            vm.weight = response.data.weight;
            vm.arterialPressure = response.data.arterialPressure;
            vm.abdominalCircumference = response.data.abdominalCircumference;
            vm.glycemia = response.data.glycemia;
            vm.alcoholism = response.data.alcoholism;
            vm.drugs = response.data.drugs;
            vm.physicalActivity = response.data.physicalActivity;
            vm.quantitySmoking = response.data.quantitySmoking;
            vm.someoneSystemicArterialHypertension = response.data.has;
            vm.someoneAcuteMyocardialInfarction = response.data.iam;
            vm.someoneDementiaSyndrome = response.data.sd;
            vm.someoneDiabetesMelitus = response.data.dm;
            vm.someoneStroke = response.data.avc;
            vm.someoneDepression = response.data.dp;
            vm.canEdit =
              vm.$props.justSeeNew === true ||
              vm.$props.justSeePerformed === true
                ? false
                : response.data.canEdit;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    fillPersonalbackground(response) {
      const vm = this;
      const surgeries = response.surgeries;
      const hospitalizations = response.hospitalizations;
      const previousDiseases = response.previousDiseases;
      const continuousMedications = response.continuousMedications;
      if(vm.testNullUndefinedEmpty(surgeries) === false){
        if(surgeries.length > 0){
          for(const s in surgeries){
            vm.surgeries[s] = surgeries[s];
          }
        }
      }
      if(vm.testNullUndefinedEmpty(hospitalizations) === false){
        if(hospitalizations.length > 0){
          for(const h in hospitalizations){
            vm.hospitalizations[h] = hospitalizations[h];
          }
        }
      }
      if(vm.testNullUndefinedEmpty(previousDiseases) === false){
        if(previousDiseases.length > 0){
          for(const p in previousDiseases){
            vm.previousDiseases[p] = previousDiseases[p];
          }
        }
      }
      if(vm.testNullUndefinedEmpty(continuousMedications) === false){
        if(continuousMedications.length > 0){
          for(const c in continuousMedications){
            vm.continuousMedicationUse[c] = continuousMedications[c];
          }
        }
      }
    },
    fillFamilyHistory(response) {
      const vm = this;
      const cancer = response.cancer;
      if(vm.testNullUndefinedEmpty(cancer) === false){
        if(cancer.length > 0){
          for(const c in cancer){
            vm.cancerHistory[c] = cancer[c];
          }
        }
      }
      vm.cancerHistory.filter((c) => c);
    },
    getRegistrationData() {
      const vm = this;
      vm.axios
        .get(
          vm.$store.state.filooServer +
            "medical-records/" +
            vm.$props.medRecId +
            "/registration-data",
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            vm.dataEntered = response.data;
            vm.dataEntered.canEdit =
              vm.$props.justSeeNew === true ||
              vm.$props.justSeePerformed === true
                ? false
                : vm.dataEntered.canEdit;
            vm.idPatient = vm.dataEntered.patientId;
            vm.genderPatient = vm.dataEntered.gender;
            vm.dataEntered.cpf = vm.maskCpf(vm.dataEntered.cpf);
            vm.dataEntered.phone = vm.maskCelPhone(vm.dataEntered.phone);
            vm.dateBirth = vm.dateFormatted(vm.dataEntered.dateBirth);
            switch (vm.dataEntered.gender) {
              case "FEMALE":
                vm.genderPatient = { name: "Feminino", value: "FEMALE" };
                break;
              case "MALE":
                vm.genderPatient = { name: "Masculino", value: "MALE" };
                break;
              default:
                break;
            }
            if (
              vm.testNullUndefinedEmpty(vm.dataEntered.maritalStatus) == false
            ) {
              vm.maritalStatusPatient = vm.dataEntered.maritalStatus;
            } else {
              vm.maritalStatusPatient = null;
            }
            if (
              vm.testNullUndefinedEmpty(vm.dataEntered.educationalAttainment) ==
              false
            ) {
              vm.educationalAttainmentsPatient =
                vm.dataEntered.educationalAttainment;
            } else {
              vm.educationalAttainmentsPatient = null;
            }
            vm.firstAppointmentDateUsFormat = vm.dateFormatted(
              vm.dataEntered.firstAppointment
            );
            vm.address = vm.dataEntered.address;
            vm.address.zipcode = vm.maskZipcode(vm.address.zipcode);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    dateFormatted(date) {
      var day = date.split("/")[0];
      var month = date.split("/")[1];
      var year = date.split("/")[2];
      return year + "-" + ("0" + month).slice(-2) + "-" + ("0" + day).slice(-2);
    },
    getMaritalStatus() {
      const vm = this;
      vm.axios
        .get(vm.$store.state.filooServer + "patient/marital-status", {
          headers: { Authorization: vm.$session.get("bearer") },
        })
        .then(function (response) {
          if (response.status === 200) {
            vm.maritalStatus = response.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getEducationalAttaiments() {
      this.axios.get(
        `${this.$store.state.filooServer}patient/educational-attainments`,
        { headers: { Authorization: this.$session.get("bearer") }},
      )
        .then(({ status, data }) => {
          if (status === 200) {
            this.educationalAttainments = data
          }
        })
        .catch((error) => {
          console.log(error)
        });
    },
    clearAddressData() {
      const vm = this;
      vm.address.street = "";
      vm.address.number = "";
      vm.address.zipcode = "";
      vm.address.state = "";
      vm.address.city = "";
    },
    async getAddress () {
      const zipcode = this.zipcode.replace(/\D/g, "")
      let validZip = zipcode && zipcode.length === 8

      if (validZip) {
        const response = await this.getAddressByZipcode(zipcode)
        validZip = response && response.address

        if (validZip) {
          this.address.zipcode = this.maskZipcode(zipcode)
          this.address.neighborhood = response.address.neighborhood
          this.address.city = response.address.city
          this.address.state = response.address.state
          this.address.street = response.address.street
          this.disabledFieldStreet = !!response.address.logradouro
        }
      }

      if (!validZip) {
        this.showNotifyWarning(
          `Não foi possível localizar o CEP digitado. Você pode deixar o campo
          sem preenchimento ou fornecer um novo CEP válido para dar sequência
          ao preenchimento do prontuário.`,
          7000
        )
        setTimeout(() => this.clearAddressData(), 1000)
      }
    },
    saveHealthData() {
      const vm = this;
      var cleanArrayCancerHistory = vm.cancerHistory.filter((c) => c);
      var cleanArrayHospitalizations = vm.hospitalizations.filter((h) => h);
      var cleanArraySurgeries = vm.surgeries.filter((s) => s);
      var cleanArrayPreviousDiases = vm.previousDiseases.filter((p) => p);
      var cleanArrayContinuousMedicationUse = vm.continuousMedicationUse.filter(
        (cm) => cm
      );

      var healthData = {
        abdominalCircumference: vm.abdominalCircumference,
        alcoholism: vm.alcoholism,
        arterialPressure: vm.arterialPressure,
        avc: vm.someoneStroke,
        bloodTransfusions: [],
        canEdit: vm.dataEntered.canEdit,
        cancer: cleanArrayCancerHistory,
        continuousMedications: cleanArrayContinuousMedicationUse,
        dm: vm.someoneDiabetesMelitus,
        dp: vm.someoneDepression,
        drugs: vm.drugs,
        glycemia: vm.glycemia,
        has: vm.someoneSystemicArterialHypertension,
        height: vm.height,
        hospitalizations: cleanArrayHospitalizations,
        iam: vm.someoneAcuteMyocardialInfarction,
        patientId: vm.dataEntered.patientId,
        patientName: vm.dataEntered.patientName,
        physicalActivity: vm.physicalActivity,
        previousDiseases: cleanArrayPreviousDiases,
        quantitySmoking: vm.quantitySmoking,
        sd: vm.someoneDementiaSyndrome,
        smoking: vm.smoking,
        surgeries: cleanArraySurgeries,
        weight: vm.weight,
      };
      vm.axios
        .put(
          vm.$store.state.filooServer +
            "medical-records/" +
            vm.$props.medRecId +
            "/health-data",
          healthData,
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            vm.showNotifySuccess(
              "Operação realizada",
              "Dados do paciente salvos com sucesso",
              3000
            );
            setTimeout(() => {
              vm.showPatientData = false;
              vm.goToMedicalCare();
            }, 200);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    saveRegistrationData() {
      const vm = this;
      const firstAppointmentFormatToBr = moment(
        String(vm.firstAppointmentDateUsFormat)
      ).format("DD/MM/YYYY");
      const dateBirthFormatToBr = moment(String(vm.dateBirth)).format(
        "DD/MM/YYYY"
      );
      const registrationtData = {
        acceptJanssenProgram: vm.dataEntered.acceptJanssenProgram,
        address: vm.address,
        age: vm.dataEntered.age,
        canEdit: true,
        cnpj: vm.dataEntered.cnpj,
        companyName: vm.dataEntered.companyName,
        contactCellPhone: vm.dataEntered.contactCellPhone,
        contactEmail: vm.dataEntered.contactEmail,
        contactWhatsApp: vm.dataEntered.contactWhatsApp,
        cpf: vm.dataEntered.cpf,
        dateBirth: dateBirthFormatToBr,
        educationalAttainment: vm.educationalAttainmentsPatient,
        email: vm.dataEntered.email,
        firstAppointment: firstAppointmentFormatToBr,
        gender: vm.genderPatient.value,
        indication: vm.dataEntered.indication,
        maritalStatus: vm.maritalStatusPatient,
        meansProfissionalContact: vm.dataEntered.meansProfissionalContact,
        naturalness: vm.dataEntered.naturalness,
        notifyByEmail: vm.dataEntered.notifyByEmail,
        notifyByFlyer: vm.dataEntered.notifyByFlyer,
        notifyByPhone: vm.dataEntered.notifyByPhone,
        notifyBySMS: vm.dataEntered.notifyBySMS,
        occupation: vm.dataEntered.occupation,
        patientId: vm.dataEntered.patientId,
        patientName: vm.dataEntered.patientName,
        phone: vm.dataEntered.phone,
        preferenceHistoric: vm.dataEntered.preferenceHistoric,
        religion: vm.dataEntered.religion,
        resideWith: vm.dataEntered.resideWith,
      };

      if (
        JSON.stringify(registrationtData.maritalStatus) ===
        JSON.stringify({ description: "", id: "" })
      ) {
        registrationtData.maritalStatus = null;
      }
      if (
        JSON.stringify(registrationtData.educationalAttainment) ===
        JSON.stringify({ description: "", id: "" })
      ) {
        registrationtData.educationalAttainment = null;
      }

      vm.axios
        .put(
          vm.$store.state.filooServer +
            "medical-records/" +
            vm.$props.medRecId +
            "/registration-data",
          registrationtData,
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            vm.saveHealthData();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    calculatingAge(birthDay) {
      const vm = this;
      const age = Math.floor(
        moment(new Date()).diff(moment(birthDay), "years", true)
      );
      vm.dataEntered.age = age;
    },
    goToMedicalCare() {
      const vm = this;
      vm.$emit("goToMedicalCare");
    },
    goToPatientHistoric() {
      const vm = this;
      vm.$emit("goToPatientHistoric");
    },
    exit() {
      const vm = this;
      vm.$bvModal.show("patient-data-exit");
    },
    exitConfirm(){
      const vm = this;
      vm.$bvModal.hide("patient-data-exit");
      setTimeout(() => {
        vm.$router.go(-1);
      }, 300);   
    },
  },

  watch: {
    dateBirth (newVal) {
      const vm = this;
      if (vm.testNullUndefinedEmpty(newVal) === false) {
        vm.calculatingAge(newVal);
      }
    },
  },
};
</script>

<style lang="scss" src="./patientData.scss" />
