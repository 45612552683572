<template src="./receivingParamsAndSettingProps.html"></template>

<script>
import mixins from "../../../../mixins/mixins.js";
import medicalRecord from "../medicalRecord/medicalRecord";
export default {
  mixins: [mixins],
  components: { "patient-medical-record": medicalRecord },
  data() {
    return {
      medRecId: null,
      justSeeNew: null,
      justSeePerformed: null,
      typeOfMedicalCare: null,
      patientId: null,
    };
  },
  created() {
    const vm = this;
    vm.verifyParamsUrl();
  },
  methods: {
    verifyParamsUrl() {
      const vm = this;
      console.log(vm.$route.params);
      if (vm.testNullUndefinedEmpty(vm.$route.params) === false) {
        vm.medRecId = parseInt(vm.$route.params.medRecId);
        vm.justSeeNew =
          vm.$route.params.justSeeNew.toString().toLowerCase() === "true"
            ? true
            : false;
        vm.typeOfMedicalCare = vm.$route.params.typeOfMedicalCare;
        vm.patientId = parseInt(vm.$route.params.patientId);
        vm.justSeePerformed =
          vm.$route.params.justSeePerformed.toString().toLowerCase() === "true"
            ? true
            : false;
      }

      console.log({
        medRecId: vm.medRecId,
        justSeeNew: vm.justSeeNew,
        justSeePerformed: vm.justSeePerformed,
        typeOfMedicalCare: vm.typeOfMedicalCare,
        patientId: vm.patientId,
      });
    },
  },
};
</script>

<style lang="scss" src="./receivingParamsAndSettingProps.scss">
</style>